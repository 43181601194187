
import FormBuilder from "@/components/FormBuilder/FormBuilder.vue";
import { mortgageForm } from "@/forms/admin/mortgage";
import {
  mortgageMapActions,
  mortgageMapMutations,
  mortgageMapState
} from "@/store/modules/mortgage";
import Vue from "vue";
import { FormBlock } from "../../../components/FormBuilder/types";
import * as types from "@/store/mutation-types";
import { mortgageDefault } from "@/helpers/mortgageDefault";
interface ICreateMortgage {
  errorMessage: string;
  successMessage: string;
  isLoading: boolean;
  validationData: Record<string, any>;
  loadingText: string;
}
export default Vue.extend({
  components: { FormBuilder },
  name: "createMortgage",
  data(): ICreateMortgage {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      validationData: {},
      loadingText: ""
    };
  },
  created() {
    this.SET_EDIT(mortgageDefault({}));
  },
  computed: {
    ...mortgageMapState(["editing", "makingApiRequest"]),
    formFields(): FormBlock[] {
      return mortgageForm;
    },
    formIsValid(): boolean {
      return !!this.validationData.formIsValid;
    }
  },
  methods: {
    ...mortgageMapActions(["createMortgage"]),
    ...mortgageMapMutations(["SET_EDIT"]),
    ...mortgageMapMutations({ editField: types.SET_EDIT_FIELD }),
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.submit();
          break;
        case "cancel":
          this.cancelCallback();
          break;
      }
    },
    async cancelCallback(): Promise<any> {
      this.$router.push("/admin/mortgages").catch(() => {});
    },
    async submit(): Promise<void> {
      try {
        if (this.formIsValid) {
          this.isLoading = true;
          this.loadingText = "Saving Data. Please wait...";
          const fieldData = this.editing;
          await this.createMortgage(fieldData);
          this.successMessage = "Mortgage created successfully";
          this.$router.replace("/admin/mortgages").catch(() => {});
        } else {
          this.errorMessage = "Please fill in all required fields";
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    }
  },
  destroyed() {
    this.SET_EDIT("");
  }
});
