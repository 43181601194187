import {
  useFormFieldGroup,
  useFormSection
} from "@/components/FormBuilder/Helpers";
import { FormBlock } from "../../components/FormBuilder/types";
import { useTextField } from "../../components/FormBuilder/Helpers/fields/useTextField";
import * as selectOptions from "@/helpers/selectOptions";
import { useSelectField } from "../../components/FormBuilder/Helpers/fields/useSelectField";

export const mortgageForm: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useTextField({
          key: "mortgageName",
          label: "Mortgage Name",
          placeholder: "Mortgage Name",
          required: true
        }),
        useTextField({
          key: "shortName",
          label: "Short Name",
          placeholder: "Short Name",
          required: false
        })
      ],
      { layout: "2-col" }
    ),
    ...useFormFieldGroup(
      [
        useTextField({
          key: "address",
          label: "Address",
          placeholder: "Address",
          required: true
        }),
        useTextField({
          key: "AICode",
          label: "AICode",
          placeholder: "AICode",
          required: true,
          validations: [
            {
              method: "integer",
              error: "AICode must be numeric only"
            }
          ]
        })
      ],
      { layout: "2-col" }
    ),
    ...useFormFieldGroup(
      [
        useTextField({
          key: "city",
          label: "City",
          placeholder: "City",
          required: true
        }),
        useSelectField({
          key: "state",
          label: "State",
          required: true,
          options: selectOptions.states,
          filterable: true,
          allowFirstOption: true
        }),
        useTextField({
          key: "zipCode",
          label: "Zip Code",
          placeholder: "Zip",
          required: true,
          validations: [
            {
              method: "minLength",
              value: 4,
              error: "Zip code must have at least four numbers"
            },
            {
              method: "integer",
              error: "Zip code must be numeric only"
            }
          ]
        })
      ],
      { layout: "3-col" }
    )
  ])
];
